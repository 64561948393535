import React from 'react';
import { BounceLoader } from 'react-spinners';
import Styles from './loading.module.css'

const Loading = () => (
  <div className={Styles[`content-loading`]}>
    <BounceLoader color="#c8102e" size="100" />
  </div>
)

export default Loading
