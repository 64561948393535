import React, { useState, lazy, Suspense } from 'react';
import './App.css';
import { Route, Switch, useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import {gsap} from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import styles from './app.module.css'
import Loading from './components/generics/Loading/Loading';

const AvisoPrivacidad = lazy(()=> import('./views/privacidad/AvisoPrivacidad'));
const Compra = lazy(() => import('./views/Compra/Compra'));
const ConfirmacionPago = lazy(()=> import('./views/ConfirmacionPago/ConfirmacionPago'));
const Contacto = lazy(() => import('./views/contacto/Contacto'));
const Corridas = lazy(()=> import('./views/Corridas/Corridas'));
const DatosPasajero = lazy(()=> import('./views/datos-pasajero/DatosPasajero'));
const DescargaFactura = lazy(()=> import('./views/descarga-factura/DescargaFactura'));
const EnrollmentResponse3ds = lazy(()=> import('./views/3dsEnrollmentResponse/EnrollmentResponse3ds'));
const Footer = lazy(()=> import('./components/footer/Footer'));
const Home = lazy(() => import('./views/home/home'));
const Mensajeria = lazy(()=> import('./views/mensajeria/Mensajeria'));
const NavBar = lazy(()=> import('./components/navbar/NavBar'));
const Nosotros = lazy(()=> import('./views/nosotros/Nosotros'));
const Page404 = lazy(()=> import('./views/404/Page404'));
const Perfil = lazy(()=> import('./views/perfil/Perfil'));
const Politicas = lazy(()=> import('./views/politicas/Politicas'));
const PreguntasFrecuentes = lazy(()=> import('./views/preguntas/PreguntasFrecuentes'));
const RecuperarContrasena = lazy(()=> import('./views/recuperar-contrasena/RecuperarContrasena'));
const Reimpresion = lazy(()=> import('./views/reimpresion/Reimpresion'));
const Servicios = lazy(()=> import('./components/servicios/Servicios'));
const SolicitudFactura = lazy(()=> import('./views/facturas/SolicitudFactura'));
const Terminales = lazy(()=> import('./views/terminales/Terminales'));
const Transporte = lazy(()=> import('./views/transporte/Transporte'));
const Gracias = lazy(() => import('./views/contacto/Gracias'));
const BajaCuenta = lazy(() => import('./components/perfil/bajaPerfil/bajaCuenta'));

// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin);
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.5)';
Modal.defaultStyles.overlay.position = 'fixed'
Modal.defaultStyles.overlay.zIndex = '1001'
Modal.defaultStyles.overlay.outline = 'none';

function App() {
  const [modalOpenBackButton, setModalOpenBackButton] = useState(false)
  const [modalCloseWindow, setModalCloseWindow] = useState(false)
  const history = useHistory();
  const urlSplit = history.location.pathname.split('/')
  const showModalBackButton = () => {
    if (urlSplit[1] !== '/'
      && urlSplit[1] !== 'reimpresion-boletos'
      && urlSplit[1] !== 'terminales'
      && urlSplit[1] !== `perfil`
      && urlSplit[1] !== `confirmacionpago`
      && urlSplit[1] !== `contacto`
      && urlSplit[1] !== `preguntas-frecuentes`
      && urlSplit[1] !== `aviso-privacidad`
      && urlSplit[1] !== `politicas-compra`
      && urlSplit[1] !== `nosotros`
      && urlSplit[1] !== `recuperarpassword`
      && history.location.pathname !== '/'
    ) {
      setModalOpenBackButton(true)
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "auto";
      window.location.href = "/";
    }
  }


  const returnHome = (e) => {
    if (e.target.id === 'redirect') {
      if( parseInt(sessionStorage.getItem('stepPago'))> 2){
        const fetchResource = async () => {
          const myHeaders = new Headers();
                myHeaders.append("Authorization", `Basic ${process.env.REACT_APP_BASIC_AUTH}`);
                myHeaders.append("Content-Type", "application/json");
          const raw = JSON.stringify({
            "ce": `${process.env.REACT_APP_CLAVE_EMPRESA}`,
            "idr": `${sessionStorage.getItem('outcomingReservation')}`,
          });
          const config = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          try  {
            const res = await fetch(`${process.env.REACT_APP_API}lr`, config)
            const response = await res.json()
            if (response.error) {
              await setTimeout(() => {
                window.location.href = "/";
              }, 200)
            }
            if(response.OK){
              await setTimeout(() => {
                window.location.href = "/";
              }, 200)
            }
          }
          catch (error)  {
            console.log(error)
          }
        }
        fetchResource();
        if(sessionStorage.getItem('viajeTipo') === 'Viaje redondo'){
          const fetchResourceVuelta = async () => {
            const myHeaders = new Headers();
                  myHeaders.append("Authorization", `Basic ${process.env.REACT_APP_BASIC_AUTH}`);
                  myHeaders.append("Content-Type", "application/json");
            const raw = JSON.stringify({
              "ce": `${process.env.REACT_APP_CLAVE_EMPRESA}`,
              "idr": `${sessionStorage.getItem('incomingReservation')}`,
            });
            const config = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
            try  {
              const res = await fetch(`${process.env.REACT_APP_API}lr`, config)
              const response = await res.json()
              if (response.error) {
                await setTimeout(() => {
                  window.location.href = "/";
                }, 200)
              }
              if(response.OK){
                await setTimeout(() => {
                  window.location.href = "/";
                }, 200)
              }
            }
            catch (error)  {
              console.log(error)
            }
          }
          fetchResourceVuelta();
        }
      } else {
        window.location.href = "/";
      }
      document.getElementsByTagName("html")[0].style.overflow = "auto";
      sessionStorage.removeItem('arrayBoletosIda');
      if(sessionStorage.getItem('arrayBoletosVuelta')){
        sessionStorage.removeItem('arrayBoletosVuelta');
      }
      sessionStorage.removeItem('arrayBoletosIda');
      sessionStorage.removeItem('stepPago');
      sessionStorage.removeItem('DateTimeReservation');
      sessionStorage.removeItem('incomingReservation');
    } else {
      setModalOpenBackButton(false)
      document.getElementsByTagName("html")[0].style.overflow = "auto";
    }
  }

  return (
    <Suspense fallback={<div />}>
      <div className='App'>
        <NavBar clickLogo={showModalBackButton} />
        <Modal
          isOpen={modalOpenBackButton}
          onRequestClose={showModalBackButton}
          className={styles[`modal-casos`]}
        >
          <div className={styles[`content-modal-casos`]}>
            <p>¿Estás seguro de que quieres regresar al inicio?</p>
            <p>Si regresas tu progreso se perderá</p>
            <button onClick={returnHome} id='redirect' className={styles[`button-red`]}>Aceptar</button>
            <button onClick={returnHome} id='cancel' className={styles[`button-return-home`]}>Cancelar</button>
          </div>
        </Modal>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/3dEnrollmentResponse' component={EnrollmentResponse3ds} />
          <Route exact path='/transporte-pasajeros' component={Transporte} />
          <Route exact path='/mensajeria-paqueteria' component={Mensajeria} />
          <Route exact path='/nosotros' component={Nosotros} />
          <Route exact path='/politicas-compra' component={Politicas} />
          <Route exact path='/aviso-privacidad' component={AvisoPrivacidad} />
          <Route exact path='/preguntas-frecuentes' component={PreguntasFrecuentes} />
          <Route exact path='/contacto' component={Contacto} />
          <Route exact path='/contacto/gracias' component={Gracias} />
          <Route exact path='/reimpresion-boletos' component={Reimpresion} />
          <Route exact path='/solicitud-factura' component={SolicitudFactura} />
          <Route exact path='/descarga-factura' component={DescargaFactura} />
          <Route exact path='/terminales' component={Terminales} />
          <Route exact path='/confirmacionpago/:idReservacion' component={ConfirmacionPago} />
          <Route exact path='/temporal' component={Servicios} />
          <Route exact path='/datos-pasajero' component={DatosPasajero} />
          <Route exact path='/viaje/:type' component={Corridas} />
          <Route exact path='/compra' component={Compra} />
          <Route exact path='/recuperarpassword/' component={RecuperarContrasena} />
          <Route exact path='/recuperarpassword/email=:email/token=:token' component={RecuperarContrasena} />
          <Route exact path='/perfil' component={Perfil} />
          <Route exact path='/bajacuenta/:ec?/:uuid?' component={BajaCuenta} />
          <Route path='/' component={Page404} />
        </Switch>
        <Footer />
      </div>
    </Suspense>
  );
}

export default App;
